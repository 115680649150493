<template>
  <v-dialog v-model="showDialog" max-width="550px">
    <v-card>
      <v-card-title class="headline" primary-title>
        Add sale
      </v-card-title>
      <v-card-text>
        <influencer-autocomplete
          v-model="saleInfo.influencer"
          :return-object="true"
        ></influencer-autocomplete>
        <campaign-autocomplete
          v-model="saleInfo.campaign"
          :return-object="true"
          :displayDraft="false"
        ></campaign-autocomplete>
        <div class="d-flex order-value-wrapper">
          <v-text-field
            v-model="saleInfo.value"
            label="Order value"
            type="number"
            class="mr-10"
            suffix="DKK"
          ></v-text-field>
          <v-text-field
            v-model="saleInfo.number"
            label="Order number (optional)"
          ></v-text-field>
        </div>
        <v-tooltip
          :disabled="$vuetify.breakpoint.xsOnly"
          bottom
          v-if="comissionCalc"
        >
          <template v-slot:activator="{ on }">
            <v-btn text color="primary" v-on="on" rounded>
              <span class="mr-3">Commission</span>
              <span>{{ comissionCalc ? comissionCalc : "--" }} DKK</span>
            </v-btn>
          </template>
          <span v-if="isPercent">Commission {{ isPercent }} %</span>
          <span v-else-if="isFixed">Commission {{ comissionCalc }} DKK</span>
        </v-tooltip>
        <v-btn text color="primary" rounded v-else>
          <span class="mr-3">Commission</span>
          <span>{{ comissionCalc ? comissionCalc : "--" }} DKK</span>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" text @click="close()">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!comissionCalc"
          @click="submit()"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import InfluencerAutocomplete from "@/components/business/autocomplete/InfluencerAutocomplete.vue";
import CampaignAutocomplete from "@/components/business/autocomplete/CampaignAutocomplete.vue";
import { mapActions } from "vuex";

export default {
  name: "CreateSaleDialog",
  components: {
    InfluencerAutocomplete,
    CampaignAutocomplete
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    saleInfo: {
      influencer: null,
      campaign: null,
      value: null,
      number: null
    },
    campaignInfluencers: [],
    loading: false
  }),
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.clearValue();
        }
        this.$emit("input", value);
      }
    },
    isСampaignPercent() {
       if(this.saleInfo.campaign &&
        this.saleInfo.campaign.cost_per_action_percent){
       return this.saleInfo.campaign.cost_per_action_percent
      } else {
        return null
      }
    },
    isPercent() {
      const data = this.campaignInfluencers.find(item => item.influencer.uuid === this.saleInfo.influencer.uuid)
      if(this.saleInfo.influencer && data) {
        return data.cost_per_action_percent
      } else if(this.saleInfo.campaign &&
        this.saleInfo.campaign.cost_per_action_percent){
       return this.saleInfo.campaign.cost_per_action_percent
      } else {
        return null
      }
    },
    isFixed() {
      if (
        this.saleInfo.campaign &&
        this.saleInfo.campaign.cost_per_action_fixed
      ) {
        return this.saleInfo.campaign.cost_per_action_fixed;
      } else {
        return null;
      }
    },
    campaign() {
      return this.saleInfo.campaign;
    },
    comissionCalc() {
      if (
        this.saleInfo.campaign &&
        this.saleInfo.influencer &&
        this.saleInfo.value
      ) {
        let comissionDifferene = null;
        for (let item of this.campaignInfluencers) {
          if (item.influencer.uuid === this.saleInfo.influencer.uuid) {
            if (this.isСampaignPercent) {
              if (this.isСampaignPercent !== item.cost_per_action_percent) {
                comissionDifferene = item.cost_per_action_percent;
              }
            }
            if (this.isFixed) {
              if (this.isFixed !== item.cost_per_action_fixed) {
                comissionDifferene = item.cost_per_action_fixed;
              }
            }
          }
        }
        if (comissionDifferene) {
          if (this.isСampaignPercent) {
            return ((this.saleInfo.value / 100) * comissionDifferene).toFixed(
              2
            );
          } else if (this.isFixed) {
            return comissionDifferene;
          } else {
            return false;
          }
        } else {
          if (this.isPercent) {
            return (
              (this.saleInfo.value / 100) *
              this.saleInfo.campaign.cost_per_action_percent
            ).toFixed(2);
          } else if (this.isFixed) {
            return this.saleInfo.campaign.cost_per_action_fixed;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["getCampaignInfluencers"]),
    ...mapActions("core", ["createSale"]),
    close() {
      this.showDialog = false;
    },
    submit() {
      if (!this.comissionCalc) {
        return false;
      }
      // request
      this.loading = true;
      const params = {
        unique_key: this.saleInfo.number,
        influencer_uuid: this.saleInfo.influencer.uuid,
        business_uuid: this.saleInfo.campaign.business.uuid,
        value: Number(this.saleInfo.value),
        commission: Number(this.comissionCalc)
      };
      this.createSale(params).then(
        sale => {
          this.loading = false;
          this.showDialog = false;
          this.$emit("createSale", sale);
        },
        error => {
          console.log(error);
        }
      );
    },
    loadCampaignInfluencers() {
      this.getCampaignInfluencers({
        campaign_uuid: this.saleInfo.campaign.uuid,
        limit: 100,
        page: 1
      }).then(
        campaignInfluencers => {
          this.campaignInfluencers = campaignInfluencers.response;
        },
        error => {
          console.log(error);
        }
      );
    },
    clearValue() {
      this.saleInfo.value = null;
      this.saleInfo.number = null;
      this.saleInfo.campaign = null;
      this.saleInfo.influencer = null;
    }
  },
   watch: {
    campaign: {
      handler(val) {
        if(val) {
          this.loadCampaignInfluencers();
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
// styles
</style>
