var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":50,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid,on:{"click":function($event){return _vm.$emit('row-click', item)}}},[(!_vm.mini)?_c('td',[(item.click_type === 'demo')?_c('v-chip',[_vm._v("Demo")]):_c('sales-status-icon',{attrs:{"sale":item}})],1):_vm._e(),_c('td',{staticClass:"word-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("utc_to_local_datetime")(item.created_at))+" ")]),(!_vm.mini)?_c('td',[(item.click)?_c('router-link',{attrs:{"to":{
                name: 'influencer',
                params: { id: item.click.influencer_channel.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.click.influencer_channel.influencer.user.first_name + " " + item.click.influencer_channel.influencer.user.last_name)+" ")]):(item.influencer.user)?_c('router-link',{attrs:{"to":{
                name: 'influencer',
                params: { id: item.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.influencer.user.full_name)+" ")]):(item.promotion_code)?_c('router-link',{attrs:{"to":{
                name: 'influencer',
                params: { id: item.promotion_code.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.promotion_code.influencer.user.first_name + " " + item.promotion_code.influencer.user.last_name)+" ")]):_vm._e()],1):_vm._e(),(!_vm.mini && !_vm.campaign)?_c('td',[(item.click)?[_vm._v(" "+_vm._s(item.click.campaign.name)+" ")]:_vm._e()],2):_vm._e(),(!_vm.mini)?_c('td',[_vm._v(" "+_vm._s(item.unique_key)+" ")]):_vm._e(),(!_vm.mini && !_vm.campaign)?_c('td',[(item.promotion_code)?[_vm._v(" "+_vm._s(item.promotion_code.code)+" ")]:_vm._e()],2):_vm._e(),_c('td',{staticClass:"text-right"},[_vm._v("DKK "+_vm._s(_vm._f("local_numbers")(item.value)))]),_c('td',{staticClass:"text-right"},[_vm._v(" DKK "+_vm._s(_vm._f("local_numbers")(item.commission))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.original_currency)+" "+_vm._s(_vm._f("local_numbers")(item.original_value))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.exchange_rate)+" ")]),(_vm.hasDemoSales)?_c('td',{staticClass:"text-right"},[(item.click_type === 'demo')?_c('span',[_c('pre',[_vm._v(_vm._s(JSON.stringify(JSON.parse(item.params), null, 2)))])]):_vm._e()]):_vm._e()])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"}),_c('div',{staticClass:"text-center",attrs:{"slot":"no-data"},slot:"no-data"},[_vm._v(" You have no sales yet. "),_c('router-link',{attrs:{"to":{ name: 'influencers' }}},[_vm._v("Search for new influencers.")]),_vm._v(". ")],1)],1),_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }