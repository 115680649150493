<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="50"
      hide-default-footer
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.uuid"
            @click="$emit('row-click', item)"
          >
            <td v-if="!mini">
              <v-chip v-if="item.click_type === 'demo'">Demo</v-chip>
              <sales-status-icon v-else :sale="item"></sales-status-icon>
            </td>
            <td class="word-no-wrap">
              {{ item.created_at | utc_to_local_datetime }}
            </td>
            <td v-if="!mini">
              <router-link
                v-if="item.click"
                :to="{
                  name: 'influencer',
                  params: { id: item.click.influencer_channel.influencer.uuid }
                }"
              >
                {{
                  item.click.influencer_channel.influencer.user.first_name +
                    " " +
                    item.click.influencer_channel.influencer.user.last_name
                }}
              </router-link>
              <router-link
                v-else-if="item.influencer.user"
                :to="{
                  name: 'influencer',
                  params: { id: item.influencer.uuid }
                }"
              >
                {{ item.influencer.user.full_name }}
              </router-link>
              <router-link
                v-else-if="item.promotion_code"
                :to="{
                  name: 'influencer',
                  params: { id: item.promotion_code.influencer.uuid }
                }"
              >
                {{
                  item.promotion_code.influencer.user.first_name +
                    " " +
                    item.promotion_code.influencer.user.last_name
                }}
              </router-link>
            </td>
            <td v-if="!mini && !campaign">
              <template v-if="item.click">
                {{ item.click.campaign.name }}
              </template>
            </td>
            <td v-if="!mini">
              {{ item.unique_key }}
            </td>
            <td v-if="!mini && !campaign">
              <template v-if="item.promotion_code">
                {{ item.promotion_code.code }}
              </template>
            </td>
            <td class="text-right">DKK {{ item.value | local_numbers }}</td>
            <td class="text-right">
              DKK {{ item.commission | local_numbers }}
            </td>
            <td class="text-right">
              {{ item.original_currency }} {{ item.original_value | local_numbers }}
            </td>
            <td class="text-right">
              {{ item.exchange_rate }}
            </td>
            <td class="text-right" v-if="hasDemoSales">
              <span v-if="item.click_type === 'demo'">
                <pre>{{ JSON.stringify(JSON.parse(item.params), null, 2) }}</pre>
              </span>
            </td>
          </tr>
        </tbody>
      </template>

      <div class="text-center" slot="no-data">
        You have no sales yet.
        <router-link :to="{ name: 'influencers' }"
          >Search for new influencers.</router-link
        >.
      </div>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import payout_status from "@/helpers/filters/payout_status";
import SalesStatusIcon from "../../icons/SalesStatusIcon";

export default {
  components: {
    SalesStatusIcon
  },
  filters: {
    local_numbers,
    utc_to_local_datetime,
    payout_status
  },
  props: {
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    mini: {
      type: Boolean,
      required: false,
      default: false
    },
    campaign: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currPage: 1
  }),
  computed: {
    headers() {
      let headers = [];

      if (!this.mini) {
        headers.push({
          text: "Status",
          sortable: false
        });
      }

      headers.push({
        text: "Date",
        sortable: false,
        value: "date"
      });

      if (!this.mini) {
        headers.push({
          text: "Influencer",
          value: "click.influencer_channel.influencer.user.first_name"
        });
      }

      if (!this.mini && !this.campaign) {
        headers.push({
          text: "Campaign",
          sortable: false,
          value: "campaign"
        });

        headers.push({
          text: "Order ID",
          sortable: false
        });

        headers.push({
          text: "Promotion code",
          sortable: false
        });
      }

      headers.push({
        text: "Revenue",
        sortable: false,
        align: "right",
        value: "value"
      });

      headers.push({
        text: "Commission",
        sortable: false,
        align: "right",
        value: "commission"
      });

      headers.push({
        text: "Originale value",
        sortable: false,
        align: "right",
        value: "original_value"
      });

      headers.push({
        text: "Exchange rate",
        sortable: false,
        align: "right",
        value: "exchange_rate"
      });

      if (this.hasDemoSales) {
        headers.push({
          text: "Params",
          sortable: false
        });
      }

      return headers;
    },
    hasDemoSales() {
      return this.items.map((item) => {
        return item.click_type;
      }).includes("demo");
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    }
  },
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", value);
    }
  }
};
</script>

<style scoped>
.v-datatable > tbody > tr > td {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
