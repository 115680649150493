<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Sales report</div>
          <span class="subtitle-1 light-grey"
            >Overview of each sale your influencers' have generated
          </span>
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                filtersTmp = cloneDeep(filters);
                filterDialog = true"
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="primary--text">
        Commission overview on sales:
        <ul>
          <li>
            Total: <span>{{ totalCommission | local_numbers }}</span>
          </li>
          <li>
            Rejected: <span>{{ disapprovedCommission | local_numbers }}</span>
          </li>
          <li>
            Approved: <span>{{ approvedCommission | local_numbers }}</span>
          </li>
          <li>
            Invoiced: <span>{{ invoicedCommission | local_numbers }}</span>
          </li>
          <li>
            Paid: <span>{{ paidCommission | local_numbers }}</span>
          </li>
        </ul>
        <div class="text-right">
          <v-btn
            color="primary"
            outlined
            class="mr-2"
            @click="openCreateSaleDialog()"
          >
            <v-icon small left>fal fa-plus</v-icon>
            Add sale
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="mr-0"
            :disabled="stats.length === 0"
          >
            <a
              class="ma-2 primary--text"
              outlined
              style="text-decoration: none"
              :href="csvData"
              target="_blank"
            >
              Download CSV
            </a>
          </v-btn>
        </div>
        <div class="text-right total-shown">
            {{ limit }} out of {{ totalAmount }}
        </div>
        <sales-data-table
          :items="stats"
          :loading="loading"
          :page="page"
          :total="total"
          @changePage="handlePageChange($event)"
        >
        </sales-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="filtersTmp.invoice_nr"
            label="Invoice nr."
            clearable
          ></v-text-field>
          <v-text-field
              v-model="filtersTmp.unique_key"
              label="Order number"
            ></v-text-field>
          <influencer-autocomplete
            v-model="filtersTmp.influencer_uuid"
            :return-object="false"
          ></influencer-autocomplete>
          <v-select
            v-model="filtersTmp.country_code"
            label="Country"
            :items="countries"
            item-text="name"
            item-value="iso_3166_2"
            :loading="!countries.length"
            clearable
          ></v-select>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Period start"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="Period end"
            cancel-button
            reset-button
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              filterDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-sale-dialog
      v-model="createSaleDialog"
      @createSale="newSaleCreated"
    ></create-sale-dialog>
  </div>
</template>
<script>
import SalesDataTable from "../../../business/data-tables/SalesDataTable";
import CreateSaleDialog from "@/components/common/dialogs/CreateSaleDialog.vue";
import DatePicker from "@/components/common/filters/DatePicker";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import InfluencerAutocomplete from "../../../autocompletes/InfluencerAutocomplete";
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions } from "vuex";

export default {
  components: {
    InfluencerAutocomplete,
    DatePicker,
    SalesDataTable,
    CreateSaleDialog
  },
  filters: { local_numbers },
  mixins: [search_filters_url],
  data: () => ({
    loading: true,
    stats: [],
    filterDialog: false,
    filtersTmp: {},
    filters: {},
    page: 1,
    total: null,
    createSaleDialog: false,
    csvData: null,
    summary: {
      total: null,
      approved: null,
      paid: null,
      invoiced: null,
      disapproved: null
    },
    loadSummary: true,
    limit: 50,
    totalAmount: null,
    countries: [],
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    },

    csvOptions() {
      return {
        fastMode: true
      };
    },
    totalCommission() {
      if (this.summary.total) {
        return this.summary.total;
      }
      return 0;
    },
    approvedCommission() {
      if (this.summary.approved) {
        return this.summary.approved;
      }
      return 0;
    },
    paidCommission() {
      if (this.summary.paid) {
        return this.summary.paid;
      }

      return 0;
    },
    invoicedCommission() {
      if (this.summary.invoiced) {
        return this.summary.invoiced;
      }

      return 0;
    },
    disapprovedCommission() {
      if (this.summary.disapproved) {
        return this.summary.disapproved;
      }

      return 0;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.loading = true;
        this.stats = [];
        this.page = 1;
        if (this.stats.length > 200) {
          setTimeout(() => {
            this.loadSummary = true;
            this.getStats();
          }, 500);
        } else {
          this.loadSummary = true;
          this.getStats();
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getCountries"]),
    ...mapActions("core", ["getSales"]),
    loadActiveCountries() {
      this.getCountries({ active: true }).then(
        countries => {
          this.countries = countries;
        },
        error => {
          console.log(error);
        }
      );
    },
    handlePageChange(page) {
      this.loadSummary = false;
      this.getStats(page)
    },
    getStats(page = 1) {
      this.stats = [];
      this.page = page;
      this.loading = true;
      const params = {
        ...this.filters,
        limit: this.limit,
        page: this.page
      };
      const filterParams = ["invoice_nr", "influencer_uuid", "unique_key", "country_code"]
      filterParams.forEach(param => {
        if (!params[param]) {
          delete params[param];
        }
      });
      this.getSales(params).then(
        sales => {
          this.total = sales.lastPage;
          this.totalAmount = sales.total;
          if(this.loadSummary) {
            this.summary = sales.summary;
          }
          if (sales.response.length) {
            sales.response.forEach(item => {
              let exists =
                this.stats.filter(itemTmp => itemTmp.uuid === item.uuid)
                  .length > 1;

              if (!exists) {
                this.stats.push(item);
              }
            });
          }
          this.csvData = sales.exportLink;
          this.loading = false;

       
        },
        () => {
          this.loading = false;
        }
      );
    },
    openCreateSaleDialog() {
      this.createSaleDialog = true;
    },
    newSaleCreated(sale) {
      this.stats.unshift(sale);
      this.$forceUpdate();
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    this.loadActiveCountries();
  }
};
</script>
